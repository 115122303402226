<template>
<div>

  <div class="full-width">
    <div class="container column">
      <h1 class="neue align-center">Iscriviti alla nostra Newsletter</h1>

      <p class="align-center">Rimani aggiornato sul mondo Altromercato. Compila il form con i dati richiesti</p>

      <br><br><br>

      <form action="https://altromercato.magnews.net/iscrizione_nl_magento" method="POST">
        <input type="hidden" autocomplete="off" name="__mn_form_tpk172" id="__mn_form_tpk172" value="true">
        <div class="col-6" style="margin-left: 25%">
          <div class="row spaced">
            <div class="col-12 containerField" id="nome_container">
              <input maxlength="255" name="nome" style="" id="nome" type="text" value="" placeholder="Nome">
            </div>
          </div>
          <div class="row spaced">
            <div class="col-12 containerField" id="cognome_container">
              <input maxlength="255" name="cognome" style="" id="cognome" type="text" value="" placeholder="Cognome">
            </div>
          </div>
          <div class="row spaced">
            <div class="col-12 containerField" id="email_container">
              <input maxlength="255" name="email" style="" id="email" type="text" value="" placeholder="Email">
            </div>
          </div>
          <div class="row spaced">
            <div class="col-12 containerField" id="datanascita_container">
              <input size="10" maxlength="10" name="datanascita" style="" id="datanascita" type="text" value="" placeholder="Data di nascita">
            </div>
					</div>
          <div class="row spaced">
            <div class="col-12">
              <div id="_mn_form_ctrl_6631_container" class="col-12"> Ho letto e accettato la Privacy Policy per il trattamento dei miei dati personali.</div>
            </div>
          </div>
          <div class="row spaced">
            <div class="col-12">
              <div class="containerField casellaControllo" id="acconsentoaltrattamentodeimieidatipersonaliperfinalitdimarketingqualinewsletterpromozioninovit_container">
                <input name="acconsentoaltrattamentodeimieidatipersonaliperfinalitdimarketingqualinewsletterpromozioninovit" style="" id="acconsentoaltrattamentodeimieidatipersonaliperfinalitdimarketingqualinewsletterpromozioninovit" type="checkbox" value="true">
                <input type="hidden" value="true" name="acconsentoaltrattamentodeimieidatipersonaliperfinalitdimarketingqualinewsletterpromozioninovit_checkbox_present">
                <label for="acconsentoaltrattamentodeimieidatipersonaliperfinalitdimarketingqualinewsletterpromozioninovit"> Acconsento al trattamento dei miei dati personali per finalità di marketing (quali newsletter, promozioni novità) come ai sensi dell'articolo 3 della <a href="/pages/politica-di-privacy" target="_blank">privacy policy</a></label>
              </div>
            </div>
          </div>
          <div class="row spaced">
            <div class="col-12 containerField" id="email_container">
              <input name="captcha" type="text" style="" id="captcha" value="" placeholder="Captcha">
              <div id="captcha_img"></div>
              <input type="hidden" name="_mn_form_ctrl_6714_seed" id="_mn_form_ctrl_6714_seed">
            </div>
          </div>
          <div class="row spaced">
            <div class="col-12 containerField" id="email_container">
              <div class="submit align-center" id="buttonsubmit_container">
               <input name="buttonsubmit" type="submit" style="" id="buttonsubmit" value="ISCRIVITI" class="button" />
              </div>
            </div>
          </div>
        </div>
      </form>

      <!--<div class="neue spacedletter">
        <iframe style="border: 0" width="100%" height="1000" src="https://altromercato.magnews.net/iscrizione_nl_magento"></iframe>
      </div>-->

      <div class="container spacer"></div>
    </div>
  </div>

</div>
</template>

<script>
export default {
  name: 'Newsletter',

  mounted () {
    var mn_base_hostname='https://altromercato.magnews.net/nl/';
    var _mn_xhr_6714 = new window.XMLHttpRequest();
    _mn_xhr_6714.open('GET', 'https://altromercato.magnews.net/nl/captcha.jpg?genseed=true&tpk=172&idc=5618&idobj=6714', true);
    _mn_xhr_6714.onreadystatechange = function () {
      if (_mn_xhr_6714.readyState === window.XMLHttpRequest.DONE) {
        var mn_json = JSON.parse(_mn_xhr_6714.responseText || '{}');
        var mn_seed_el = document.getElementById('_mn_form_ctrl_6714_seed');
        if (mn_seed_el && mn_json.seed) {
          mn_seed_el.value = mn_json.seed
        }
        var mn_seed_img_el = document.createElement("img");
        mn_seed_img_el.setAttribute("id", "captcha_img");
        mn_seed_img_el.setAttribute("src", mn_base_hostname + mn_json.imgsrc);
        mn_seed_img_el.classList = ["captcha"];
        var mn_mock_div = document.getElementById('captcha_img');
        if (mn_mock_div && mn_mock_div.parentNode) {
          mn_mock_div.parentNode.replaceChild(mn_seed_img_el, mn_mock_div);
        }
      }
    };
    _mn_xhr_6714.send();
  }
}
</script>

<style scoped>
.container.column {
  flex-direction: column;
}

.spacedletter {
  letter-spacing: 0.5px;
}

input[type="text"],
input[type="password"]
{
  width: 100%;
  margin-right: 15px;
  height: 32px;
  line-height: 32px;
  padding-left: 10px;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ddd;
  border-radius: 16px;
}

.row.spaced {
  margin-bottom: 20px;
}

.row.spaced .col-6,
.row.spaced .col-12 {
  padding-right: 15px;
  font-family: neue-haas-grotesk-display, sans-serif;
}
</style>

